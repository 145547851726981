export default function () {
    const searchButton = document.querySelector('.m-agents__reservations__search-btn');
    searchButton.addEventListener('click', function(event) {

        const bookingDetailsDiv = document.querySelector('.m-agents__reservations__booking-details');
        const errorMessageDiv = document.querySelector('.m-agents__reservations__error-message');
        const input = document.querySelector('.m-agents__reservations__search-bar');
        const referenceNumber = input.value;

        errorMessageDiv.innerHTML = '';
        bookingDetailsDiv.innerHTML = '';

        if (!referenceNumber) {
            errorMessageDiv.innerHTML = 'Please enter a valid reference (ST followed by 6 digits)';
            return;
        }

        $.ajax({
            url: `/agents/booking/${referenceNumber}`, 
            type: 'GET', 
            success: function (data) {
                    bookingDetailsDiv.innerHTML = data;
                    input.value = '';
            },
            error: function (error) {
                errorMessageDiv.innerHTML = 'There was an error, please contact Simpson Travel:' + error;
            }
        });
    });
}